html {
  scroll-behavior: smooth;
}

.memotes {
  display: flex;
}

.memotes img {
  max-width: 80px;
  min-height: 40px;
}

