.about-us__title {
  color: #fff;
  font-family: 'Rubik One';
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin-top: 132px;
  margin-bottom: 52px;
}

.about-us__team__container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 52px;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  padding-left: 36px;
  padding-right: 36px;
  margin-bottom: 110px;
}

.about-us__caption {
  color: #fff;
  text-align: center;
  font-family: 'Proxima Nova', sans-serif;
  font-size: 25px;
  line-height: 1.3;
  padding-bottom: 100px;
  padding-left: 200px;
  padding-right: 200px;
}

@media (max-width: 768px) {
  .about-us__title {
    font-size: 2rem;
  }

  .about-us__caption {
    font-size: 1rem;
    padding: 0px 75px 100px;
  }
}
