@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=M+PLUS+1p&family=Rubik+Doodle+Shadow&display=swap');

.metrics {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 80px;
    margin: 130px 180px 180px;
}

.metric-item {
    flex: 1 1 300px; 
    max-width: 100%; 
    text-align: center; 
    padding: 20px;
    box-sizing: border-box; 
}

.gif {
    max-width: 100%;
    height: auto;
}

h3.metrics-title {
    color: #fff;
    font-size: 2rem; 
    margin: 0 10 30px; 
}

.metrics-description {
    color: #fff;
    font-family: "Proxima Nova", sans-serif;
    font-size: 1.5rem; 
    line-height: 1.5; 
    margin: 0; 
}

@media (max-width: 768px) {
    .metrics {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 80px;
        margin: 83px 60px 88px;
    }

    .metrics-description {
        font-size: 1rem; 
    }
}