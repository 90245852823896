@keyframes slideUpKeys {
  100% {
    bottom: 90%;
  }
}

@keyframes slideUpKeys-left {
  100% {
    bottom: 90%;
    left: 0%;
  }
}

@keyframes slideUpKeys-right {
  100% {
    bottom: 90%;
    left: 100%;
  }
}

.emoji-container {
  bottom: 0;
  left: 0;
  position: absolute;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
}

@keyframes bounce {
  0% {
    bottom: calc(70%);
  }
  100% {
    bottom: 0;
  }
}

@keyframes moveX-left {
  100% {
    left: 100%;
  }
}

@keyframes moveX-right {
  100% {
    left: 0%;
  }
}

@keyframes moveY {
  100% {
    height: 0;
  }
}
