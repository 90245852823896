.banner__container {
  text-align: center;
  display: grid;
  padding-top: 100px;
  position: relative; 
  z-index: 3; 
}

.animated {
  z-index: 3;
}

.banner__container__tittle {
  color: white;
  font-size: 65px;
  font-weight: 500;
  margin-bottom: 0px;
  margin-top: 60px;
  z-index: 3;
}

.banner__container__subtitle {
  font-size: 28px;
  margin-top: 10px;
  color: #a6a6a6;
  z-index: 3;
}

.twitch {
  top: 110px;
  left: 50%;
  position: absolute; 
  z-index: 0;
  width: 400px;
  height: 410px;
  background: radial-gradient(circle at 56% 40%, transparent 35%, #6441a4 35%) 0 0,
    linear-gradient(90deg, transparent 62%, #6441a4 60%) 5px;
  background-size: 100% 100%, 100px 190px;
  clip-path: polygon(
    /* outer-shape */ 100% 0,
    100% 62%,
    72% 88%,
    52% 88%,
    40% 100%,
    25% 100%,
    25% 88%,
    0 88%,
    0 17%,
    7% 0,
    100% 0,
    /* middle-shape */ 90% 10%,
    16% 10%,
    16% 72%,
    37% 72%,
    37% 84%,
    50% 72%,
    75% 72%,
    90% 57%,
    90% 10%,
    /*inner-shape*/ 75% 25%,
    75% 53%,
    40% 53%,
    40% 25%,
    75% 25%
  );
}

@media only screen and (max-height: 840px) {
  .banner__container__tittle {
    margin-top: 20px;
  }
}

@media only screen and (max-height: 770px) {
  .banner__container__tittle {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .banner__container {
    padding-top: 60px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .twitch {
    top: 100px; 
    width: 180px; 
    height: 180px; 
    background-size: 100% 100%, 30px 95px; 
    clip-path: polygon(
      /* outer-shape */ 100% 0,
      100% 62%,
      72% 88%,
      52% 88%,
      40% 100%,
      25% 100%,
      25% 88%,
      0 88%,
      0 17%,
      7% 0,
      100% 0,
      /* middle-shape */ 90% 10%,
      16% 10%,
      16% 72%,
      37% 72%,
      37% 84%,
      50% 72%,
      75% 72%,
      90% 57%,
      90% 10%,
      /* inner-shape */ 75% 25%,
      75% 53%,
      40% 53%,
      40% 25%,
      75% 25%
    );
  }

  .banner__container__tittle {
    font-size: 48px;
    margin-top: 80px;
  }

  .banner__container__subtitle {
    font-size: 22px;
  }
}