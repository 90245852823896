.generate-link-form__container {
  backdrop-filter: blur(8px);
  background-color: rgb(0 0 0/0.7);
  height: 235px;
  width: 80%;
  max-width: 700px;
  vertical-align: middle;
  text-align: center;
  display: grid;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  padding-bottom: 30px;
  padding-top: 30px;
  position: relative;
  z-index: 3;
}

.generate-link-form__container input {
  z-index: 3;
  width: 400px;
  height: 30px;
  margin: auto;
  font-size: 18px;
  text-align: center;
}

.generate-link-form__container button {
  z-index: 3;
  width: 408px;
  height: 38px;
  margin: auto;
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 18px;
  color: white;
  background-color: #6441a4;
  border: solid 1px #6441a4;
  border-radius: 2px;
  cursor: pointer;
}

.generate-link-form__container button:hover {
  background-color: #36215c;
  border: solid 1px #4b307c;
  transition: 0.3s;
}

.generate-link-form__copied-tooltip span {
  color: white;
  background-color: #4a4a4a;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  position: absolute;
  margin-top: -42px;
  z-index: 10;
  cursor: pointer;
}

.generate-link-form__copied-tooltip span::before {
  content: '.';
  color: #4a4a4a;
  background-color: #4a4a4a;
  padding: 1px 10px;
  margin-top: 6px;
  position: absolute;
  rotate: 45deg;
  z-index: -1;
}

.generate-link-form__copy-button {
  margin-top: -20px !important;
}

@media only screen and (max-width: 768px) {
  .generate-link-form__container {
    height: 200px;
  }

  .generate-link-form__container input {
    width: 260px;
    border-radius: 2px;
  }

  .generate-link-form__container button {
    width: 260px;
    border-radius: 5px;
  }

  .generate-link-form__copied-tooltip span {
    margin-left: -156px;
  }
}
