.how-it-works {
  padding: 235px 50px 100px;
  background-color: #6442a5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  border-top: 1px solid #111;
}

.how-it-works-title {
  font-size: 64px;
  color: #fff;
  font-family: 'Rubik One';
  font-style: normal;
  font-weight: 400;
}

.intro {
  font-size: 32px;
  color: #fff;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
}

.bold-700 {
  font-weight: 700;
}

.bold-800 {
  font-weight: 800;
}

.video-container {
  margin: 20px 0;
  text-align: center;
}

.numbered-paragraphs {
  font-size: 32px;
  color: #fff;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 500;
}

.disc {
  list-style-type: disc;
}

@media only screen and (max-width: 768px) {
  .how-it-works-title {
    font-size: 1.8rem; 
    margin-top: -110px;
  }

  .intro {
    font-size: 1rem;
  }

  .video-container iframe {
    width: 100%;
    height: auto; 
    max-width: 300px; 
    border: 5px solid #fff; 
  }

  .numbered-paragraphs {
    font-size: 1rem;
  }

}