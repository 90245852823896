.scroll-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 54px;
    height: 54px;
    background-color: #333333;
    background-image: url("./../../assets/images/up-arrow.png");
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    border-radius: 50%;
    padding: 20px;
    cursor: pointer;
    z-index: 1000;
}