.teamate__image {
  background-color: #6442a5;
  background-position: center;
  border-radius: 50%;
  height: 212px;
  width: 212px;
}

.teamate__name {
  color: #fff;
  text-align: center;
  margin-top: 14px;
  font-size: 22px;
  font-weight: 500;
}

.teamate__name a {
  color: #fff;
  text-decoration: none;
}

.teamate__name a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .teamate__image {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 180px;
    width: 180px;
  }

  .teamate__name {
    font-size: 18px;
  }
}
