.donate-wrapper {
  height: 235px;
  max-width: 700px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
  padding-top: 100px;
}

.image-container {
  text-align: center;
  margin-right: 16px;
}

.gif-image {
  margin-top: -72px;
  width: 215px;
}

.content-container {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.buyme {
  color: white;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.1em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  margin-bottom: 9px;
}

.button-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 1;
}

.button-container form {
  width: 100%;
}

.custom-button {
  border-radius: 32px;
  background: #fbd61e;
  cursor: pointer;
  border: none;
  padding: 11px 62px;
  color: #243782;
  font-size: 23px;
  font-weight: 800;
  letter-spacing: 2px;
  width: 100%;
}

.credit-cards-container {
  display: flex;
  height: 54px;
}

.credit-cards {
  margin-top: 12px;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .donate-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    margin: auto auto;
    height: 100px;
    margin-bottom: 252px;
    padding-top: 50px;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: space-around;
    margin-left: 0px;
  }

  .image-container {
    margin-bottom: 36px;
    margin-right: 0px;
  }
}
