.home__container {
  display: flex;
  flex-direction: column;
  background-color: #151515;
  position: relative;
  width: 100%;
  margin: 0 auto; 
}

.additional-content {
  padding: 100px;
  background-color:#1C1C1C;
  margin-top: 100px;
}

.home__emoji_container {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}
