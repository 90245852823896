.footer__container {
  bottom: 0px;
  background-color: #1c1c1c;
  width: 100%;
}

.footer__powered-by {
  height: 60px;
  background-color: black;
  color: #444444;
  text-align: center;
  display: grid;
  margin: auto;
}

.footer__powered-by span {
  margin: auto;
}

.footer__follow {
  display: block;
  color: #a6a6a6;
  padding: 20px 40px;
  font-weight: 500;
  text-align: center;
}

.footer__follow__icons {
  display: inline-flex;
  margin-left: auto;
  margin-right: auto;
}

.footer__follow__icons__icon {
  margin: 8px;
  cursor: pointer;
  width: 40px;
  margin-bottom: 0px;
}

.footer__follow__icons__icon:hover {
  margin-top: 0px;
  width: 48px;
  transition: 0.3s;
}

@media only screen and (max-height: 768px) {
  .footer__container {
    position: relative;
    margin-top: 20px;
  }
}
