@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.nav-bar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(8px);
  background-color: rgb(0 0 0 / 0.7);
  height: 80px;
  color: white;
  padding-left: 40px;
  padding-right: 40px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.nav-bar__title {
  margin-top: 1.3rem;
  margin-bottom: auto;
  display: block;
  position: relative;
}

.nav-bar__title__twitch {
  width: 50px;
  position: absolute;
  margin-top: -4px;
}

.nav-bar__title__twitch:hover {
  width: 60px;
  margin-top: -10px;
  margin-left: -5px;
  transition: 0.3s;
}

.nav-bar__title__jmemo162 {
  width: 200px;
  position: absolute;
  display: block;
  margin-left: 60px;
}

.nav-bar__right {
  display: flex;
  align-items: center;
  margin-left: auto; 
}

.nav-bar__section {
  color: white;
  text-decoration: none;
  font-family: "Press Start 2P", system-ui;
  font-weight: 400;
  margin: 0 25px;
}

.nav-bar__section:hover {
  color: #6442A5;
}

.nav-bar__social-icons {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.nav-bar__social-icons__icon {
  cursor: pointer;
  width: 40px;
  margin: 0 15px;
  transition: 0.3s;
}

.nav-bar__toggle {
  display: none;
}

.nav-bar__menu {
  display: none;
}

@media (max-width: 1440px) {
  .nav-bar__sections,
  .nav-bar__social-icons {
    display: none;
  }

  .nav-bar__toggle {
    display: block;
    cursor: pointer;
    font-size: 37px;
    color: white;
  }

  .nav-bar__menu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
    background-color: rgb(0 0 0 / 0.7);
    width: 100%;
    position: absolute;
    top: 80px;
    right: 0;
    z-index: 10;
    padding: 20px;
  }

  .nav-bar__menu__close {
    position: absolute;
    cursor: pointer;
    color: white;
    font-size: 33px;
    top: -74px;
    right: 20px;
    padding: 20px;
  }

  .nav-bar__menu__close:hover {
    color: #6442a5;
  }

  .nav-bar__menu__section {
    color: white;
    text-decoration: none;
    font-family: "Press Start 2P", system-ui;
    font-weight: 400;
    margin: 0px 65px;
  }

  .nav-bar__menu__section:hover {
    color: #6442A5;
  }

  .nav-bar__menu__icon {
    cursor: pointer;
    width: 40px;
    margin: 0px 65px;
    transition: 0.3s;
  }
}
