@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=M+PLUS+1p&family=Rubik+Doodle+Shadow&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap');

.what-is-this{
  background-color: #1C1C1C;
}

.wtf-title {
  color: transparent; 
  -webkit-text-fill-color: white; 
  -webkit-text-stroke-width: 3px; 
  -webkit-text-stroke-color: black;
    text-align: center;
    font-family: "Rubik Mono One", sans-serif;
    font-size: 50px;
    font-weight: 400; 
    margin: 250px 20px 20px;
    position: relative;
    z-index: 2; 
  }

.wtf-title2 {
  color:  rgba(101, 66, 165, 0.36);
  text-align: center;
  font-family: "Rubik Mono One";
  font-size: 99px;
  font-weight: 400; 
  position: relative;
  top: -223px; 

}

  .wtf-description {
    color: #fff;
    text-align: center;
    font-family: "Proxima Nova", sans-serif;
    font-size: 25px;
    line-height: 1.3;
    gap: 20px;
    margin: -260px 200px 200px;
  }

@media (max-width: 768px) {
    .wtf-title {
      font-size: 40px;
      top: -135px;
    }

    .wtf-title2{
      font-size: 70px;
      top: -388px; 
    }
    
    .wtf-description {
      font-size: 1rem; 
      margin: -460px 62px 75px;
    }
  }
  